import {
  AuthenticationResult,
  Configuration,
  EventType,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

const b2cPolicies = {
  names: {
    signIn: 'B2C_1A_SIGN_IN',
  },
  authorities: {
    signIn: {
      authority: `https://${process.env.AZURE_AD_B2C_CUSTOM_DOMAIN}/${process.env.AZURE_AD_B2C_TENANT_ID}/B2C_1A_SIGN_IN`,
    },
  },
  authorityDomain: process.env.AZURE_AD_B2C_CUSTOM_DOMAIN,
};

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.AZURE_AD_B2C_CLIENT_ID,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.debug(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.debug(message);
            return;
        }
      },
    },
  },
};

const scopes = (apiType: ApiType) => {
  switch (apiType) {
    case 'foroCrmApi':
      return [
        `https://${process.env.AZURE_AD_B2C_TENANT_NAME}/foro-crm-api/app`,
      ];
    case 'medupForoQueryService':
      return [
        `https://${process.env.AZURE_AD_B2C_TENANT_NAME}/${process.env.AZURE_AD_B2C_QUERY_SERVICE_APP_URI}/app`,
      ];
    case 'medupForoCommandService':
      return [
        `https://${process.env.AZURE_AD_B2C_TENANT_NAME}/${process.env.AZURE_AD_B2C_COMMAND_SERVICE_APP_URI}/app`,
      ];
    default:
      throw new Error('invalid apiType');
  }
};

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export type ApiType =
  | 'foroCrmApi'
  | 'medupForoQueryService'
  | 'medupForoCommandService';
export const generateAcquireAccessToken = (apiType: ApiType) => async () => {
  return acquireAccessToken(apiType);
};
export const acquireAccessToken = async (apiType: ApiType) => {
  const activeAccount = msalInstance.getActiveAccount();

  if (activeAccount === null) {
    throw new Error('active account not found error');
  }

  try {
    const authResult = await msalInstance.acquireTokenSilent({
      scopes: scopes(apiType),
      account: activeAccount,
    });

    return authResult.accessToken;
  } catch (error) {
    // 更新トークンの期限が切れ、対話的認証が要求された場合の対応として、ログイン画面にリダイレクトさせるようにしている。
    // https://learn.microsoft.com/ja-jp/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript2#acquire-a-token-with-a-redirect
    // https://github.com/MicrosoftDocs/azure-docs.ja-jp/blob/master/articles/active-directory/develop/msal-error-handling-js.md
    if (error instanceof InteractionRequiredAuthError) {
      // リダイレクト以後のコードは実際には実行されない
      await msalInstance.acquireTokenRedirect({
        scopes: scopes(apiType),
        account: activeAccount,
      });
    }

    throw error;
  }
};
